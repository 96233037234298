class Vault {
    constructor() {
        if (!Vault._instance) {
            this.vaultItems = {};
            this._expTimers = {};
            Vault._instance = this;
        }
        return Vault._instance;
    }

    static getInstance() {
        return new Vault();
    }

    getItem(key) {
        return this.vaultItems[key];
    }

    setItem(key, value, expInSeconds) {
        this.vaultItems[key] = value;
        this._expTimers[key] = null;
        if (expInSeconds != null && typeof(expInSeconds) === 'number') {
            clearTimeout(this._expTimers[key]);
            this._expTimers[key] = setTimeout(() => {
                this.removeItem(key);
            }, expInSeconds * 1000);
        }
        return this.vaultItems[key];
    }

    removeItem(key) {
        clearTimeout(this._expTimers[key]);
        // console.log(`Removing [${key}] from Vault`);
        delete this.vaultItems[key];
        return !(key in this.vaultItems);
    }
}

export default Vault;
