import { useContext } from 'react';
import { ToastContext } from '../../providers/Toast.provider';
import './Toast.scss';
import { FaTimes } from 'react-icons/fa';

function Toast() {
    const { toasts, removeToast } = useContext(ToastContext);

    function getToasts() {
        return toasts.map((toast, index) => (
            <div className={`toast toast--${toast.type}`} key={index}>
                {toast.text}
                <button className="toast-close" onClick={() => removeToast(toast.id) }>
                    <FaTimes />
                </button>
            </div>
        ));
    }

    return (
        <div className="toast-container">
            {getToasts()}
        </div>
    );
}

export { Toast };
