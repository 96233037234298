import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Vault from '../helpers/Vault';

function PrivateRoute({ Component }) {
    const vault = Vault.getInstance();
    const isLoggedIn = vault.getItem('session') || false;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`/unlock?r=${btoa(location.pathname)}`, { replace: true });
        }
    }, [isLoggedIn, navigate, location.pathname]);

    return (<>{isLoggedIn && (<Component />)}</>);
}

export default PrivateRoute;
