import { Link, useNavigate } from 'react-router-dom';
import { FaPen, FaTrash } from 'react-icons/fa';
import './Recipe.scss';
import { useContext } from 'react';
import { ToastContext } from '../../providers/Toast.provider';
import { deleteRecipe } from '../../views/recipe-editor/services/delete-recipe.service';

function Recipe({ recipe }) {
    const { createToast } = useContext(ToastContext);
    const navigate = useNavigate();

    const handleDelete = () => {
        const confirmed = window.confirm(`Confirmar eliminación de la receta "${recipe.title}"`);
        if (!confirmed) {
            return;
        }

        deleteRecipe(recipe.recipe_id)
            .then(response => {
                if (!response.deleted) {
                    throw new Error();
                }
                createToast({
                    type: 'success',
                    text: `La receta ${recipe.title} ha sido eliminada.`,
                    ttl: 3000,
                });

                setTimeout(() => {
                    navigate('/');
                }, 1500);
            }).catch(() => {
                createToast({
                    type: 'danger',
                    text: 'Ocurrió un problema al eliminar la receta.',
                });
            });
    }

    return (
        <div className="recipe">
            <div className="recipe-header">
                <img className="recipe-header-image" src={recipe.image || '/files/default_recipe.jpg'} alt={recipe.title} />
                <div className="recipe-details">
                    <h1 className="recipe-header-title">{recipe.title}</h1>
                    {recipe.description && (
                        <p className="recipe-header-description">{recipe.description}</p>
                    )}
                    {recipe.tags && (
                        <div className="tag-list">
                            {recipe.tags.map((tag, index) => (<div className="tag" key={index}>{tag}</div>))}
                        </div>
                    )}
                    <div className="recipe-options">
                        <Link to={`/receta/${recipe.recipe_id}/edit`}
                            className="recipe-option">
                            <FaPen />
                        </Link>
                        <button className="recipe-option" onClick={handleDelete}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
            <div className="recipe-body">
                <div className="recipe-body-block">
                    <h2>Ingredientes:</h2>
                    <ul>
                        {recipe.ingredients.map((ingredient, index) => (
                            <li className="recipe-body-list-item" key={index}>
                                <input id={`ingredient-${index}`} type="checkbox"></input>
                                <label htmlFor={`ingredient-${index}`}>{ingredient}</label>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="recipe-body-block">
                    <h2>Instrucciones:</h2>
                    <ol>
                        {recipe.instructions.map((instruction, index) => (<li className="recipe-body-list-item" key={index}>{index + 1}. {instruction}</li>))}
                    </ol>
                </div>

            </div>
        </div>
    );
}

export default Recipe;
