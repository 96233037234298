import './CardList.scss';
import Card from '../card/Card';

function CardList({ recipes, search = '', loading }) {
    const searchDom = search.length !== 0 && (<> para la búsqueda '<i>{search}'</i></>)

    function getLoader() {
        return loading && (<div className="loader">
            <img src="/loader.svg" alt="loader" />
            <p>Cargando recetas</p>
        </div>);
    }

    return (
        <div className={`recipes ${recipes.length ? '' : 'empty'}`}>
            {getLoader()}
            {recipes.length === 0 && !loading && (
                <div className="message">
                    <p>
                        No hay recetas disponibles{searchDom}.
                    </p>
                </div>
            )}
            {recipes.length !== 0 && recipes.map((recipe) => (
                <Card key={recipe.recipe_id} {...recipe}></Card>
            ))}
        </div>
    );
};

export default CardList;
