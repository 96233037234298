import { useState, useEffect, useCallback } from 'react';
import CardList from '../../components/card-list/CardList';
import Search from '../../components/search/Search';
import Vault from '../../helpers/Vault';
import { getAllRecipes } from '../../helpers/Request';
import useTitle from '../../hooks/useTitle';
import { APP_NAME } from '../../constants/app-name';
const vault = Vault.getInstance();

export function Home() {
    const [recipes, setRecipes] = useState([]);
    const [viewingRecipes, setViewingRecipes] = useState(vault.getItem('home/viewingRecipes') || []);
    const [search, setSearch] = useState(vault.getItem('home/search') || '');
    const [loading, setLoading] = useState(true);

    useTitle('Inicio')

    const filterRecipes = (arr, filter) => {
        return arr.filter(recipe => {
            return recipe.title.toLowerCase().includes(filter) || recipe.tags.some(tag => tag.toLowerCase().includes(filter));
        });
    };

    const searchHandler = useCallback((text) => {
        const search = text.toLowerCase();
        setSearch(text);
        vault.setItem('home/search', text);
        const recipesFiltered = filterRecipes(recipes, search);
        setViewingRecipes(recipesFiltered);
        vault.setItem('home/viewingRecipes', recipesFiltered);
    }, [recipes]);

    useEffect(() => {
        setLoading(true);
        if (recipes?.length) {
            setViewingRecipes(filterRecipes(recipes, search));
            setLoading(false);
            return;
        }
        const fetchRecipes = () => {
            getAllRecipes()
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    vault.setItem('home/recipes', data.recipes, 60);
                    setRecipes(data.recipes);
                    setViewingRecipes(filterRecipes(data.recipes, search));
                })
                .catch(_ => {
                    alert('Ocurrió un error al cargar las recetas.');
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchRecipes();
    }, [recipes, search]);

    return (
        <>
            <h1 className="application-name tac">{APP_NAME}</h1>
            <Search onSearch={searchHandler} init={search} />
            <CardList recipes={viewingRecipes} search={search} loading={loading} />
        </>
    );
}
