const hostUrl = '/';
const updateRecipePath = 'api/recipes/'

async function updateRecipe(recipeId, formData) {
    return fetch(`${hostUrl}${updateRecipePath}${recipeId}`, {
        method: 'PATCH',
        body: formData,
        headers: {
            authorization: `Bearer ${localStorage.getItem('session')}`,
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Invalid response code');
        });
}

export { updateRecipe };
