import './NavBar.scss';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function NavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [canGoBack, setCanGoBack] = useState(location.pathname === '/');

    useEffect(() => {
        setCanGoBack(location.pathname !== '/' && location.pathname !== '/unlock');
    }, [location.pathname])

    const handleNavigateBack = (ev) => {
        ev.preventDefault();
        navigate(-1);
    };

    const handleNavigateHome = (ev) => {
        ev.preventDefault();
        navigate('/');
    };

    return (
        <div className="navbar">
            {((canGoBack && <div className="navbar-back" onClick={handleNavigateBack}>
                <FaArrowLeft className="back-icon" />
            </div>) || <div></div>)}
            <div className="navbar-middle">
                <img className="navbar-logo" src="/design.png" alt="Logo" onClick={handleNavigateHome}/>
            </div>
        </div>
    );
}

export default NavBar;
