import { FaPlus } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import './new-recipe.scss';

export const NewRecipe = () => {
    const location = useLocation();

    function conditionalRender() {
        return location.pathname === '/' &&
        (
        <Link className="btn-new-recipe" to="/receta/nueva">
            <FaPlus />
            <span>Agregar Receta</span>
        </Link>
        );
    }

    return (
        <>
        {conditionalRender()}
        </>
    );
};
