const hostUrl = '/';
const deleteRecipePath = 'api/recipes'

async function deleteRecipe(recipeId) {
    return fetch(`${hostUrl}${deleteRecipePath}/${recipeId}`, {
        method: 'DELETE',
        headers: {
            authorization: `Bearer ${localStorage.getItem('session')}`,
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Invalid response code');
        });
}

export { deleteRecipe };
