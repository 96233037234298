import { useEffect } from "react"
import { APP_NAME } from "../constants/app-name";

const updater = (newState) => {
    document.title = `${newState} ● ${APP_NAME}`;
}

function useTitle(initialState) {
    useEffect(() => {
        document.title = `${initialState} ● ${APP_NAME}`;
    }, [initialState]);

    return [initialState, updater];
}

export default useTitle;
