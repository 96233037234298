import { useRef, useState } from 'react';
import './array-group.scss';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';

function ArrayGroup({ items = [], handleModification = () => { }, handleReorder = () => { }, validators = [] }) {
    const newItemRef = useRef();
    const addBtnRef = useRef();
    const containerRef = useRef();
    const [addButtonEnabled, setAddButtonEnabled] = useState(false);

    const isInputValid = (value) => {
        return validators.length === 0 || validators.every(validator => validator(value));
    }

    const handleRemoveItemClick = (ev, index) => {
        ev.preventDefault();
        handleModification({
            type: 'remove',
            index,
        });
    };

    const handleTextModification = (ev, index) => {
        ev.preventDefault();
        const value = ev.nativeEvent.target.value;
        if (validators.length === 0 || validators.every(validator => validator(value))) {
            handleModification({
                type: 'textChange',
                index,
                text: value,
            });
        }
    }

    const handleAddItemClick = (ev) => {
        ev.preventDefault();
        const value = newItemRef.current.value;
        if (isInputValid(value)) {
            handleModification({
                type: 'new',
                text: newItemRef.current.value,
            });
            newItemRef.current.value = '';
            newItemRef.current?.focus?.();
        }
    }

    const handleInput = (ev) => {
        setAddButtonEnabled(isInputValid(ev.nativeEvent.target.value));
    }

    const handleKeyDown = (ev) => {
        if (ev.nativeEvent.key === 'Enter') {
            ev.preventDefault();
            addBtnRef.current?.click();
        }

        if (ev.nativeEvent.key === 'Escape') {
            ev.nativeEvent.target.value = '';
        }
    }

    const dragStartHandler = function(e) {
        e.target.classList.add('dragging');
    }

    const getItemsHTML = () => {
        return items.map((item, index) => (
            <div className="items-row movable-row" key={index} draggable={true} onDragStart={dragStartHandler} id={item}>
                <input
                    className='input-text double'
                    onChange={(ev) => handleTextModification(ev, index)}
                    type="text"
                    value={item} />
                <button
                    className="btn btn--danger"
                    onClick={(ev) => handleRemoveItemClick(ev, index)}>
                    <FaTrash />
                </button>
            </div>
        ));
    }

    const dropHandler = function(e) {
        e.preventDefault();
        const draggingEl = containerRef.current.querySelector('.dragging');
        const text = draggingEl.querySelector('input').value;
        const children = [...containerRef.current.querySelectorAll('.movable-row')];
        const afterEl = containerRef.current.querySelector('.inserting-before');
        const beforeEl = containerRef.current.querySelector('.inserting-after');

        let targetPos = 0;

        if (afterEl) {
            targetPos = children.indexOf(afterEl) - 1;
        }
        if (beforeEl) {
            targetPos = children.indexOf(beforeEl);
        }

        for (const child of children) {
            child.classList.remove(
                'dragging',
                'inserting-before',
                'inserting-after',
            );
        }

        handleReorder({
            text,
            targetPosition: targetPos,
        });
    }

    const dragOverHandler = function(e) {
        e.preventDefault();
        const { clientY } = e;
        const children = [...containerRef.current.querySelectorAll('.movable-row')];
        let index = 0;
        for (; index < children.length; ++index) {
            const { y, height } = children[index].getBoundingClientRect();
            if (clientY <= (y + (height / 2))) {
                break;
            }
        }
        const targetPosition = index - 1;
        const targetEl = children[targetPosition + 1];

        for (const child of children) {
            child.classList.remove('inserting-before', 'inserting-after');
        }

        if (targetEl) {
            targetEl.classList.add('inserting-before');
        } else {
            children[children.length - 1].classList.add('inserting-after');
        }
    }

    return (
        <div ref={containerRef} className="array-group" onDrop={dropHandler} onDragOver={dragOverHandler}>
            {getItemsHTML()}
            <div className="items-row">
                <input
                    className='input-text single'
                    type="text"
                    ref={newItemRef}
                    onKeyDown={handleKeyDown}
                    onInput={handleInput}
                    placeholder="Nuevo.." />
                <button
                    className="btn"
                    ref={addBtnRef}
                    disabled={!addButtonEnabled}
                    onClick={(ev) => handleAddItemClick(ev)}>
                    <FaPlusCircle />
                </button>
            </div>
        </div>
    );
};

export default ArrayGroup;
