import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { RecipeEditor } from './components/recipe-editor/recipe-editor';
import { getRecipe } from '../../helpers/Request';
import useTitle from '../../hooks/useTitle';

export function RecipeEditorView () {
    const { id } = useParams();
    const [recipe, setRecipe] = useState(null);
    const [loader, setLoader] = useState(true);

    useTitle('Editar Receta');

    useEffect(() => {
        setLoader(true);
        getRecipe(id)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                setRecipe(data);
            })
            .catch(_ => {
                alert('Ocurrió un error al cargar la receta.');
            })
            .finally(() => {
                setLoader(false);
            });
    }, [id]);

    function getLoader() {
        return loader && (<div className="loader">
            <img src="/loader.svg" alt="loader" />
            <p>Cargando receta</p>
        </div>);
    }

    return (
        <>
            {getLoader()}
            {recipe && !loader && (<RecipeEditor recipe={recipe}/>)}
        </>
    );
}
