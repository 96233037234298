import Vault from "./Vault";
const vault = Vault.getInstance();

function _getHeaders() {
    return {
        'Authorization': `Bearer ${vault.getItem('session')}`,
    };
}

function getAllRecipes() {
    return fetch(`/api/recipes/all`, {
        headers: _getHeaders(),
    });
}

function getRecipe(recipeId) {
    return fetch(`/api/recipes/${recipeId}`, {
        headers: _getHeaders(),
    });
}

function check() {
    const authLocal = localStorage.getItem('session');
    const headers = _getHeaders();
    if (authLocal) {
        vault.setItem('session', authLocal);
    }
    return fetch('/api/alive', {
        headers: authLocal ? { 'Authorization': `Bearer ${authLocal}` } : headers,
        method: 'POST',
    })
        .then(response => {
            if (response.status !== 200) {
                return false;
            }
            return true;
        });
}

export {
    getAllRecipes,
    getRecipe,
    check,
};
