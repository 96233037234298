import './App.scss';
import { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/nav-bar/NavBar';
import PrivateRoute from './components/PrivateRoute';
import { Home, RecipeDetail, RecipeEditorView, Unlock } from './views';
import { Toast } from './components/toasts/Toasts';
import { ToastContext } from './providers/Toast.provider';
import { RecipeCreatorView } from './views/recipe-creator/recipe-creator';
import { NewRecipe } from './components/new-recipe/new-recipe.component';

function App() {
  const [toasts, setToasts] = useState([]);

  const removeToast = (toastId) => {
    setToasts(prevState => prevState.filter(t => t.id !== toastId));
  }

  const toastProviderValue = {
    toasts,
    createToast: ({ type, text, ttl = 3000 }) => {
      const id = `${Date.now()}${Math.random() * 1e4}`
      const newToast = { type, text, ttl, id };
      if (ttl !== 0) {
        setToasts(prevState => [...prevState, newToast]);
        setTimeout(() => {
          removeToast(newToast.id);
        }, ttl);
      }
    },
    removeToast,
  };

  return (
    <ToastContext.Provider value={toastProviderValue}>
      <BrowserRouter >
        <NavBar></NavBar>
        <div className="view">
          <Routes>
            <Route exact path="/unlock" element={<Unlock />} />
            <Route path="receta/nueva" element={<PrivateRoute Component={RecipeCreatorView} />} />
            <Route path="receta/:id/edit" element={<PrivateRoute Component={RecipeEditorView} />} />
            <Route path="receta/:id" element={<PrivateRoute Component={RecipeDetail} />} />
            <Route path="/" element={<PrivateRoute Component={Home} />} />
          </Routes>
          <NewRecipe />
          <Toast />
        </div>
      </BrowserRouter>
    </ToastContext.Provider>
  );
}

export default App;
