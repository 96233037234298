import './Card.scss';
import { Link } from 'react-router-dom';

function Card(props) {
    return (
        <Link to={`/receta/${props.recipe_id}`} className="card-link">
            <div className="card">
                <div className="card-content" title={props.title}>
                    <h1 className="card-header-title">{props.title}</h1>
                    <p className="card-ingredients">{props.ingredients.join(', ')}</p>
                    <div className="card-tags tag-list">
                        {props.tags.map((tag, index)=> (
                            <p className="tag" key={`${tag}-${index}`}>{tag}</p>
                        ))}
                    </div>

                </div>

                <div className="card-image-container">
                    <img className="card-image" src={props.image || '/files/default_recipe.jpg'} alt={props.title} />
                </div>

            </div>
        </Link>
    );
};

export default Card;
