import "./Search.scss";
import { useRef, useState } from 'react';

function Search({ onSearch, init = '' }) {
    const searchRef = useRef();
    const [value, setValue] = useState(init);

    const inputHandler = (ev) => {
        if (ev.key === 'Escape') {
            searchRef.current.value = '';
        }
        const text = searchRef.current.value;
        setValue(text);
        onSearch(text);
    };

    return (
        <div className="search-input-container">
            <input className="search-input" ref={searchRef} type="text" onInput={inputHandler} onKeyDown={inputHandler} placeholder="Buscar por título o etiqueta..." value={value} autoFocus={true}></input>
        </div>
    );
}

export default Search;
